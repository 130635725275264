export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTOMS": {
          "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])}
        },
        "HEADERS": {
          "CODIGO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codigo"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
          "PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
          "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "STORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacen"])},
          "AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
          "RACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estante"])}
        },
        "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron registros"])}
      }
    }
  })
}
