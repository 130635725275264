
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { FormTypes } from "@/core/enums/form-types";
import { debounce } from "lodash";
import Drawer from "@/components/shared/Drawer.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import InputText from "@/components/forms/InputText.vue"
import { Form } from "vee-validate";
import PhysicalCountTable from './components/PhysicalCountTable.vue'

export default defineComponent({
    components:{
        PhysicalCountTable
        , Drawer
        , InputText
        , Form
    },
    setup(){

        //PROPIEDADES
        const showFilters = ref(false);
        let modeForm = ref(FormTypes.Create);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Producto",
            titleNewCrudAction: "Nuevo Movimiento",
        });
        //moduleTable.SEARCH_TABLE_DATA();
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "codigo", HeadLabel: "Codigo", Type: "text", VisibleInGrid: true },
            { PropName: "description", HeadLabel: "Descripción", Type: "text", VisibleInGrid: true },
            { PropName: "count", HeadLabel: "Cantidad", Type: "number", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "number", VisibleInGrid: true },          
            { PropName: "total", HeadLabel: "Total", Type: "number", VisibleInGrid: true },          
        ]);   

        //METODOS
        const handleChangePage = (page: number) => {
            //moduleTable.CHANGE_PAGE({ currentPage: page });
            //console.log(`call handleChangePage(${page})`);   
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            if(typeof action === "number"){
                modeForm.value = action;
            } else {
                modeForm.value = action == "N" ? FormTypes.Create
                                            : (action == "M" ? FormTypes.Update 
                                                                : action == "V" ? FormTypes.Read : FormTypes.Create);
            }
            
            
            if(modeForm.value != FormTypes.Create){
                //buscar los datos
               
            } else {
                //reset values
            }
            showFilters.value = true;
            //showModalById(id_modal_form_clients);
        }

        const searchMovements = () => {
            //serach movements
        }
        const searchClient = () => {
            //buscar cleitnes
        }

        const filterNameChange = debounce((filterName: string) => {
            //update filters
        }, 550, {leading: false, trailing: true});

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable([], configTable);
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Invetario Fisico", ["Módulos"]);
        })

        //DATOS DEL STORE
        const totalRecords = computed(() => 1);
        const sizePage = computed(() => 10);

        return {
            showFilters
            , labels
            , totalRecords
            , sizePage
            , headers
            , renderRows
            , modeForm
            , searchMovements
            , handleChangePage
            , handleCrudAction
            , filterNameChange
            , searchClient
        }
    }
})
