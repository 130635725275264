
import { computed, defineComponent, ref } from "vue";
import * as Validations from "yup";
import { Form } from "vee-validate";
import DetailsWarehouseFields from "./DetailsWarehouseFields.vue"
import { emit } from "process";
import OptionSelect from "@/models/shared/OptionSelect";
export default defineComponent({
//se debe agregar una lista de detalles de entrada... y tener
//el objeto de la entrada para crear
    components:{
        DetailsWarehouseFields
        , Form
    },
    props:{
        values:{
            type:Object
            , default: () => {
                return {
                    productId: ''
                    , quantity: 1
                    , price: 0
                    , unitOfMeasurementId: ''
                }
            }
        }
    },
    emits:['save-article'],
    setup(_, {emit}){
        const request = ref({
            storeId: ''
            , observations: 'Creado automaticamente en conteo fisico - ajustes'
        });
        const newProducts = ref([]);

        const validationSchema = computed(() => Validations.object().shape({
            //clientId: Validations.string().required()
            productId: Validations.string().required(),
            quantity: Validations.number().required(),
            price: Validations.number().required().typeError("Sólo se adminiten valores numéricos."),
            //total: Validations.number().required().typeError("Sólo se adminiten valores numéricos.").label("Total"),
            unitOfMeasurementId: Validations.string().required()
        }));

        const saveArticle = (form) => {
            form.unitOfMeasurementName = 'Test'//getunitOfMeasurementName(form.unitOfMeasurementId)
            form.productName = 'Product Name'//getProductName(form.productId)
            //add to list new article
            emit('save-article', form);
        }

        const optionProducts = computed(() => [{ id: '1', name: 'Aluminios'}] as OptionSelect[]);
        const unitMesuarementOptions = computed(() => [{ id: '1', name: 'Metros'}] as OptionSelect[]);
        const clientOptions = computed(() => []);

        return {
            clientOptions
            , validationSchema
            , saveArticle
            , optionProducts
            , unitMesuarementOptions
        }
    }
})
