export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "APPLICATION_MOVEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación de movimientos"])},
          "SEARCH_SALES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar ventas"])}
        },
        "TOOLTIPS": {
          
        },
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda avanzada"])},
          "INVENTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventario"])},
          "AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
          "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "SUB_FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Familia"])}
        }
      }
    }
  })
}
