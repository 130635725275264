
import { createExcelFileUrl } from "@/core/helpers/excelTransform";
import { defineComponent, ref, toRefs} from "vue";
import AddArticleForm from "./AddArticleForm.vue"

export default defineComponent({
    components:{
        AddArticleForm
    },
    props: {
        useModeForm: {
            type: Boolean
            , default: false
        }
        , rows: {
            type: Array
            , default: () => []
        }
        , subHeader:{
            type: String
            , default: ''
        }
    },
    setup(props){
        const { rows } = toRefs(props)
        const hideErrors = ref(true);
        const headers = ref(['CODIGO'
                        , 'DESCRIPTION'
                        , 'QUANTITY'
                        , 'PRICE'
                        , 'TOTAL']);
        
        const entries = (quantityActual, quantityPhysic): number => {
            return ( quantityActual < quantityPhysic) ?  quantityPhysic - quantityActual : 0
        }
        const outputs = (quantityActual, quantityPhysic): number => {
            return ( quantityActual > quantityPhysic) ?  quantityActual - quantityPhysic : 0
        }
        const diff = (quantityActual, quantityPhysic): number =>
        {
             return Math.abs(quantityActual - quantityPhysic)
        }
        const columnExcel = [
            {
                columName: 'Código'
                , objectNameValue: 'codigo'
            },
            {
                columName: 'Descripción'
                , objectNameValue: 'description'
            },
            {
                columName: 'Cantidad DB'
                , objectNameValue: 'quantity'
            },
            {
                columName: 'Cantidad contada'
                , isBlank: true
                , objectNameValue: ''
            },
            {
                columName: 'Justificación'
                , isBlank: true
                , objectNameValue: ''
            }
        ]
        const downloadTemplate = async () => {
            
            createExcelFileUrl(columnExcel, rows.value, { fileName: 'Plantilla', worksheetName: 'Articulos Conteo Fisico'} )

        }

        const validPhysicCounter = () => {
            let errors = [];
            //validar datos ingresados
            rows.value.forEach((row) => {

                //if (!row.quantityPhysic && row.quantityPhysic < 0)
                    //hideErrors.value = false;

            })
        }

        const addArticle = () => {
            //agrega nuevo articulo al rack
            rows.value.push( {
                        codigo: ''//codigo delproducto o del lugar del almacen
                        , description: ''//nombre del producto
                        , id: ''//identificador de la busqueda realizada
                        , havePendingMovements: false
                        , price: 0//precio del detalle de entrada
                        , quantity: 0//cantidad de articulos en almacen
                        , total: 0//cantidad por el precio
                        , isNew: true
                        , haveInfo: false//indica que ya se guardo el registro
                        , detailInfo: {
                            productId: ''
                            , unitMesuareId: ''
                            , unitMesuareName: ''
                        }
                    });
        }
        return {
            headers
            , diff
            , entries
            , outputs
            , downloadTemplate 
            , addArticle
        }
    }
})
