
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Paginator from "@/components/c-paginator/Paginator.vue";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import ConfigSectionColumnsItems from "@/models/physicalInventory/config-section-columns/ConfigSectionColumnsItems";
import Loading from "@/components/shared/Loading.vue";
import PhysicalInventoryItemRow from "@/models/physicalInventory/PhysicalInventoryItemRow";
import StoreInformationItem from "@/models/physicalInventory/StoreInformation";
import PhysicalCountTableItems from "../components/PhysicalCountTableItems.vue";
import Drawer from "@/components/shared/Drawer.vue"

export default defineComponent({
    components: {
        Paginator
        , NoDataMessage
        , Loading
        , PhysicalCountTableItems
        , Drawer
    },
    setup(){
        const showForm = ref(false);
        const rowsSelected = ref([]);
        const subHeaderArticles = ref('');
        const headers = ref(['STORE'
                            , 'AREA'
                            , 'RACK']);
        const subHeaders = ref(['CODIGO'
                            , 'DESCRIPTION'
                            , 'QUANTITY'
                            , 'PRICE'
                            , 'TOTAL']);
        const filedsExcel = ref({
            Codigo: "codigo"
            , Identificador: "id"
            , "Nombre articulo": "description"
            , "Cantidad DB.": "quantity"
            , "Cantidad real":""
        });
        const groups = ref([
            { 
                canDoPhysicCount: true
                , storeInfo: {
                    areaId: '1'
                    , areaName: 'Herramintas'
                    , rackId: '2'
                    , rackName: 'Martillos'
                    , storeId: '3'
                    , storeName: 'Almacen-1'
                }
                , name: 'Almacen-1 > Herramintas > Martillos'
                , rows: [
                    {
                        codigo: '1'
                        , description: 'ts'
                        , id: '2'
                        , havePendingMovements: false
                        , price: 200
                        , quantity: 5 
                        , total: 200
                    },
                    {
                        codigo: '2'
                        , description: 'achu'
                        , id: '1'
                        , havePendingMovements: false
                        , price: 15
                        , quantity:19 
                        , total: 15
                    }] 
            },
             { 
                canDoPhysicCount: false
                , storeInfo: {
                    areaId: '11'
                    , areaName: 'Herreria'
                    , rackId: '21'
                    , rackName: 'Laminas'
                    , storeId: '31'
                    , storeName: 'Almacen-2'
                }
                , name: 'Almacen-2 > Herreria > Laminas'
                , rows: [
                    {
                        codigo: '2'
                        , description: 'ts2'
                        , id: '3'
                        , havePendingMovements: false
                        , price: 230
                        , quantity: 2 
                        , total: 460
                    }] 
            }
        ] as ConfigSectionColumnsItems[]);
        const loading = ref(false);

        const rows = ref([
            {
                storeInfo: {
                    areaId: ''
                    , areaName: ''
                    , rackId: ''
                    , rackName: ''
                    , storeId: ''
                    , storeName: ''
                } as StoreInformationItem
                , codigo: '1'
                , description: 'ts'
                , id: '2'
                , havePendingMovements: false
                , price: 200
                , quantity: 1 
                , total: 200
            }
        ] as PhysicalInventoryItemRow[])
        
        return {
            headers
            , subHeaders
            , groups
            , loading
            , rows
            , showForm
            , rowsSelected
            , filedsExcel
            , subHeaderArticles
        }
    }
})
