export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "HEADERS": {
          "CODIGO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cant. BD."])},
          "PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
          "QUANTITY_PHYSIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cant. Fisíca"])},
          "DIFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia"])},
          "ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
          "OUTPUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salidas"])},
          "JUSTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificación"])},
          "QUANTITY_BEFORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cant. Actual"])},
          "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      }
    }
  })
}
