import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailsWarehouseFields = _resolveComponent("DetailsWarehouseFields")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.validationSchema,
      onSubmit: _ctx.saveArticle,
      "initial-values": _ctx.values,
      class: "mx-auto w-100 pt-1 pb-1",
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DetailsWarehouseFields, {
          optionProducts: _ctx.optionProducts,
          unitMesuarementOptions: _ctx.unitMesuarementOptions
        }, null, 8, ["optionProducts", "unitMesuarementOptions"])
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit", "initial-values"])
  ]))
}