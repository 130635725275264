<template>
    <section class="row g-4 mb-0">
        <div class="col-md-3 col-sm-12 fv-row">
            <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.PRODUCTS") }}</label>
            <SelectFilterRemote :name="'productId'" 
                :placeholder="$t('PLACEHOLDERS.PRODUCTS')"
                :options="optionProducts"
                 @search="(query) => $emit('searchProducts', query)"
                @change="(idSelected) => $emit('changeProductSelect', idSelected)">
            </SelectFilterRemote>
        </div>
        <div class="col-md-3 col-sm-12 fv-row">
            <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.UNIT_MESUAREMENT") }}</label>
            <Select :name="'unitOfMeasurementId'" 
                :placeholder="$t('PLACEHOLDERS.UNIT_MESUAREMENT')"
                :options="unitMesuarementOptions">
            </Select>
        </div>
         <div class="col-md-2 col-sm-12 fv-row">
            <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.QUANTITY") }}</label>
            <InputNumber :name="'quantity'" 
                :min="1">
            </InputNumber>
        </div>
        <div class="col-md-3 col-sm-12 fv-row">
            <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.PRICE") }}</label>
            <InputText :name="'price'" 
                :mask="maskMoney">
            </InputText>
        </div>
        <div class="col-md-1 col-sm-12 fv-row d-flex align-items-center justify-content-center">
            <button class="btn btn-sm  btn-primary btn-active-light-primary p-2 me-3">
                <i class="bi bi-plus text-center fs-2"></i>
            </button>
             
        </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import InputNumber from "@/components/forms/InputNumber.vue" 
import InputText from "@/components/forms/InputText.vue" 
import Select from "@/components/forms/Select.vue" 
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";

export default defineComponent({
    components:{
        SelectFilterRemote
        , InputNumber
        , InputText
        , Select
    },
    emits:['searchProducts', 'changeProductSelect'],
    props:{
        optionProducts:{
            type: Array
            , required: false
        },
        unitMesuarementOptions:{
            type: Array
            , required: false
        }
    },
    setup(){
        return {
            maskMoney: InputTextMaskTypes.Money
        }
    }
})
</script>

<style>

</style>